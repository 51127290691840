<template>
    <div class="details-content">
        <!-- 新增及编辑  科普 -->
        <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
        <el-tabs v-model="activeName">
            <el-tab-pane label="新增科普" name="first" lazy>
               <div class="details-container">
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                       <el-row>
                           <el-col :span="12">
                               <el-form-item label="科普标题" prop="newsName">
                                    <el-input v-model="ruleForm.newsName" maxlength="20" placeholder="请输入标题名称，20字以内"></el-input>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                                <el-form-item label="标题图片">
                                     <uoloadSingleImg 
                                        v-model="ruleForm.uploadPictures" 
                                        ref="uoloadSingleImg" 
                                        form="上传" 
                                        :uploadUrl="uploadUrl" 
                                        :fileList="fileList" @childByValue="childByValue" :tip="tip" :uploadSize="2" 
                                        :uoloadSingleType="'img'" 
                                        :uoloadSingleStatus='uoloadSingleStatus'
                                     ></uoloadSingleImg>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                                <el-form-item label="资讯内容" prop="content">
                                    <el-input v-model="ruleForm.content" v-show="false"></el-input>
                                     <div class="editor-container" style="line-height:22px;">
                                        <UE :defaultMsg=defaultMsg :config=config ref="ue" v-if="ue"></UE>
                                     </div>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24" class="text-left">
                                <el-button type="success"  @click="submitForm('ruleForm')" :loading="loading">{{loading?'发布中':'发布'}}</el-button>
                           </el-col>
                       </el-row>
                   </el-form>
               </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import uoloadSingleImg from "../../components/uploadSingleImg";
import UE from "../../components/editor";
import { AddArticle } from "../../api/api"; //添加
import { EditArticle } from "../../api/api"; //修改
import { ArticleInfo } from "../../api/api"; //详情
import { UploadNewsImg } from "../../api/api"; //上传图片
export default {
  components: {
    UE,
    uoloadSingleImg
  },
  data() {
    var checkContent = (rule, value, callback, text) => {
      // 获取富文本内容
      let contentUE = this.$refs.ue.getUEContent();
      if (contentUE != "" && contentUE != undefined) {
        this.ruleForm.content = contentUE;
        callback();
      } else {
        callback(new Error("请填写资讯内容"));
      }
    };
    return {
      id: this.$route.query.id, //科普id
      activeName: "first",
      defaultMsg: "", //富文本默认值
      config: {
        initialFrameWidth: null,
        initialFrameHeight: 350
      },
      tip: "请上传140*120尺寸的图片", //上传提示信息
      fileList: [], //默认显示图片
      uploadUrl: "",
      uoloadSingleStatus:'',
      ruleForm: {
        newsName: "", //科普名字
        content: "", //内容
        uploadPictures: "" //图片
      },
      rules: {
        newsName: [
          { required: true, message: "请输入科普标题", trigger: "change" }
        ],
        content: [
          { required: true, trigger: "change", validator: checkContent }
        ]
      },
      ue: false,
      loading: false,
    };
  },
  created() {
    this.uploadUrl = UploadNewsImg;
    if (this.id != "") {
      //详情
      this.uoloadSingleStatus='edit';
      var db = { id: this.id };
      this.$axios.post(ArticleInfo, db).then(
        res => {
          if (res.data.code == 1) {
            this.ruleForm.content = res.data.data.content;
            this.defaultMsg = res.data.data.content;
            this.ue = true;
            this.ruleForm.newsName = res.data.data.title;
            this.fileList = res.data.data.imgs;
            var imgUrl = [];
            for (var i = 0; i < this.fileList.length; i++) {
              imgUrl.push(this.fileList[i].url);
            }
            this.ruleForm.uploadPictures = imgUrl.join(",");
          } else {
            this.$message.error(res.data.msg);
          }
        },
        err => {
          this.$message.error(err.msg);
        }
      );
    } else {
      this.uoloadSingleStatus='add'
      this.ue = true;
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contentManageNews" });
    },
    //接受图片子组件传回来的值
    childByValue(val, type) {
      this.fileList = [val];
      this.ruleForm.uploadPictures = val;
    },
    submitForm(formName) {
      //保存
      this.$refs[formName].validate(valid => {
        if (valid) {
           
          var postUrl;
          var db;
          if (this.id != "") {
            //修改
            postUrl = EditArticle;
            db = {
              title: this.ruleForm.newsName, //	标题
              imgs: this.ruleForm.uploadPictures, //	图片
              content: this.ruleForm.content, //	内容
              id: this.id
            };
          } else {
            //添加
            postUrl = AddArticle;
            db = {
              title: this.ruleForm.newsName, //	标题
              imgs: this.ruleForm.uploadPictures, //	图片
              content: this.ruleForm.content //	内容
            };
          }
          this.$axios.post(postUrl, db).then(
            res => {
              this.loading = true;
              if (res.data.code == 1) {
                this.loading = false;
                this.goBack();
              } else {
                this.loading = false;
                this.$message.error(res.data.msg);
              }
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
